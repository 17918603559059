<template>
	<div>
		<div class="mb-6 max-w-[20rem]">
			<h2 class="pb-4 title-3">{{ name }}</h2>
			<div class="pb-2 body-text-2">{{ title }}</div>
	
			<div v-on:click="isOpen = !isOpen" class="body-text-2 underline hover:cursor-pointer">Read more</div>
		</div>

		<div :class="{ 'z-[-50] opacity-0': !isOpen, 'z-[3000] opacity-1': isOpen }" class="fixed inset-0 flex items-center justify-center text-left transition-opacity duration-300 ease-in-out transform">
			<div class="h-full px-6 py-10 overflow-scroll bg-white border-4 sm:h-auto border-rose">
				<div class="flex h-full sm:items-center sm:block">
					<div>
						<div class="flex items-center justify-between mb-6">
							<div class="flex items-center">
								<div class="w-[7rem] h-[7rem] sm:w-[10rem] sm:h-[10rem] mr-4 overflow-hidden rounded-full flex items-center justify-center">
									<img class="w-[7rem] sm:w-[10rem]" :src="photo" alt="">
								</div>
								<div class="">
									<h2 class="title-4 text-navy whitespace-nowrap">{{ name }}</h2>
									<div class="text-md text-navy">{{ title }}</div>
								</div>
								
							</div>
							<div v-on:click="isOpen = !isOpen" class="hidden text-3xl font-bold sm:block text-navy hover:cursor-pointer">
								✕
							</div>
		
						</div>
						
						
						<div class="body-text-1 text-navy max-w-[40rem]">{{ description }}</div>
						
						<div v-on:click="isOpen = !isOpen" class="pb-10 mt-10 text-4xl font-bold text-center sm:hidden text-navy hover:cursor-pointer">
							✕
						</div>
					</div>
				</div>
				
				
			</div>
			
		</div>
		
		<div :class="{ 'opacity-0 z-[-50]': !isOpen, 'opacity-[0.9] z-[100]': isOpen }" class="fixed inset-0 transition-opacity duration-500 ease-in-out transform bg-navy">

		</div>
	</div>
</template>

<script>
	export default {
		name: 'LeaderModals',
		props: ['name','title','description','photo'],
		data() {
			return {
				isOpen: false,
			}
		}
	}
</script>
