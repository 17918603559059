<script>
	import NewsletterSignup from './components/NewsletterSignup.vue'
	import LeaderModals from './components/LeaderModals.vue'

	export default {
		name: 'App',
		components: {
			NewsletterSignup,
			LeaderModals,
		}
	}
</script>